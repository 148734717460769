import { AntdLayout } from "@pankod/refine-antd";

export const AppFooter: React.FC = () => {

    return (
        <AntdLayout.Footer style={{ textAlign: 'center' }}>
            {/* <Space wrap split={<Divider type="vertical" />} >
                <img src="/tcs-logo-2.png" alt="TCS" style={{ maxWidth: "21px" }} />
                <Typography.Text style={{ color: "GrayText" }}>TATA Consultancy Services</Typography.Text>
                <Typography.Text style={{ color: "GrayText" }}><span style={{ color: "limegreen" }}>A</span>rchitecture, <span style={{ color: "limegreen" }}>I</span>nnovation and <span style={{ color: "limegreen" }}>T</span>ransformation</Typography.Text>
                <Typography.Text style={{ color: "GrayText" }}>{process.env.REACT_APP_ApplicationTitle} <Icons.CopyrightOutlined /> {new Date().getFullYear()}</Typography.Text>
                {
                    process.env.REACT_APP_BuildId &&
                    <Typography.Text style={{ color: "GrayText" }}>Version: {process.env.REACT_APP_BuildId}</Typography.Text>
                }
            </Space> */}
        </AntdLayout.Footer>
    );
};