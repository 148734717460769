import { AntdLayout, Grid } from "@pankod/refine-antd";
import { LayoutProps } from "@pankod/refine-core";
import React from "react";
import { getAppTheme } from "scripts/theme";

export const AppLayout: React.FC<LayoutProps> = ({
    children,
    Header,
    Sider,
    Footer,
    OffLayoutArea,
}) => {
    const breakpoint = Grid.useBreakpoint();
    //const { isLoading } = useAuthZ();

    const divStyle = {
        padding: breakpoint.sm ? 24 : 12,
        minHeight: 360
    };

    return (
        <AntdLayout className={`hlm-${getAppTheme()}`} style={{ minHeight: "100vh", flexDirection: "row" }}>
            {Sider && <Sider />}
            <AntdLayout>
                {Header && <Header />}
                <AntdLayout.Content>
                    <div style={divStyle}>
                        {children}
                    </div>
                    {OffLayoutArea && <OffLayoutArea />}
                </AntdLayout.Content>
                {Footer && <Footer />}
            </AntdLayout>
        </AntdLayout>
    );
};
