import { AntdList, Avatar, Button, Descriptions, Empty, Icons, Input, Show } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustomMutation, useGetIdentity } from "@pankod/refine-core";
import { IUser } from "interfaces";
import { useState } from "react";
import { API_PATH, LOGO_PATH, RESOURCE_PATH } from "scripts/site";
const { Search } = Input;

export declare type chatModel = {
  actor: "AI" | "User";
  conversation: {
    contextual: string
    standard: string
  };
  error: boolean;
  //avatar?: string;
};

export const ChatList: React.FC<IResourceComponentsProps> = () => {
  //const apiPath = 'https://getsecure-api.azurewebsites.net/api/Chat'
  //const [conversationText, setConversationText] = useState("");
  const [chatData, setChatData] = useState<chatModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useCustomMutation();
  const [inputValue, setInputValue] = useState('');

  const apiCall = (userText: string) => {
    setIsLoading(true);
    // Clear the chat history if there were any errors occured
    if (chatData.some(c => c.error)) {
      chatData.splice(0);
      setChatData(chatData);
    }

    chatData.push({
      actor: "User",
      conversation: {
        contextual: userText,
        standard: userText
      },
      error: false
    });
    setChatData(chatData);
    //setConversationText("");    
    setInputValue('');

    mutate({
      url: API_PATH + "/" + RESOURCE_PATH.CHAT,
      method: "post",
      values: {
        chatContext: chatData,
        query: userText
      }
    },
      {
        onSuccess: (data, variables, context) => {
          chatData.push({
            actor: "AI",
            conversation: {
              contextual: data?.data?.contextual,
              standard: data?.data?.standard,
            },
            error: false
          });
          setChatData(chatData);
          setIsLoading(false);
        },
        onError: (data, variables, context) => {
          //if (data.statusCode === 403) {
          chatData.push({
            actor: "AI",
            conversation: {
              contextual: data?.statusCode + " - " + data?.response?.statusText + ": " + data?.response?.data,
              standard: data?.statusCode + " - " + data?.response?.statusText + ": " + data?.response?.data,
            },
            error: true
          });
          setChatData(chatData);
          setIsLoading(false);
          //}
        }
      }
    );
  }
  // const { data: chat } = useOne({
  //   resource: RESOURCE_PATH.CHAT,
  //   id: "",
  //   queryOptions: {
  //     enabled: true
  //   }, 
  //   successNotification(data, values, resource) {

  //   },
  // });
  const { data } = useGetIdentity();
  const user = data as IUser;
  const userAlias = user?.name?.split("@")[0] ?? "User";

  return (
    <Show
      canDelete={false}
      headerProps={{
        backIcon: false,
        title: "Chat",
        extra: chatData.length > 0 && <Button danger icon={<Icons.ClearOutlined />} onClick={() => { setChatData([]) }}>Clear</Button>
      }}>

      <div
        id="scrollableDiv"
        style={{
          maxHeight: 500,
          overflow: 'auto',
          marginBottom: 20,
          //padding: '0 16px',
          //border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        {chatData.length > 0 ?
          <AntdList
            loading={isLoading}
            itemLayout="vertical"
            pagination={false}
            dataSource={chatData}
            renderItem={(item) => (
              item.actor === "AI" ?
                <Descriptions bordered>
                  <Descriptions.Item >
                    <AntdList.Item>
                      <AntdList.Item.Meta
                        avatar={<Avatar src={LOGO_PATH} />}
                        title={"GetSecure chat bot"}
                        description={item.conversation.contextual} />
                    </AntdList.Item>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <AntdList.Item>
                      <AntdList.Item.Meta
                        avatar={<Avatar src={"logo-open-ai.png"} />}
                        title={"Chat GPT response"}
                        description={item.conversation.standard} />
                    </AntdList.Item>
                  </Descriptions.Item>
                </Descriptions> :
                <AntdList.Item style={{ border: "none" }}>
                  <AntdList.Item.Meta
                    avatar={<Avatar src={"user.png"} />}
                    title={userAlias}
                    description={item.conversation.contextual} />
                </AntdList.Item>
            )}
          >
          </AntdList>
          : <Empty description="No conversations" image={Empty.PRESENTED_IMAGE_DEFAULT}></Empty>}
      </div>
      {/* <Space.Compact block>
      <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="https://ant.design" />
      <Button type="primary">Submit</Button>
    </Space.Compact> */}
      <Search
        placeholder="Ask me anything"
        enterButton={<>Send  <Icons.SendOutlined /> </>}
        size="large"
        loading={isLoading}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onSearch={apiCall}
      />
    </Show>
  );
};
