import { Tooltip, Typography } from "@pankod/refine-antd";
import { clearLocalSessionStorage, LOGO_PATH } from "scripts/site";

export const AppTitle: React.FC = (params: any) => {
    return (
        <Tooltip title="Reload">
            <a href="/" onClick={() => clearLocalSessionStorage(false)}>
                <img
                    src={LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle}
                    className="app-title-small"
                />
                {params.collapsed
                    ? <></>
                    : <Typography.Text className="app-title">
                        GetSecure AI
                    </Typography.Text>
                }
            </a>
        </Tooltip>
    );
};