import { Icons } from "@pankod/refine-antd";
import { ResourceProps } from "@pankod/refine-core";
import { ChatList } from "pages/chat";
import { RESOURCE_PATH } from "scripts/site";

export const heliumResources: ResourceProps[] = [
    {
        name: RESOURCE_PATH.CHAT,
        key: RESOURCE_PATH.CHAT,
        list: ChatList,
        //options: { label: "My Profile" },
        icon: <Icons.CommentOutlined />
    },

    // {
    //     name: RESOURCE_PATH.ABOUT,
    //     key: RESOURCE_PATH.ABOUT,
    //     list: About,
    //     canDelete: false,
    //     options: { label: "About" },
    //     icon: <Icons.InfoOutlined />
    // }
];