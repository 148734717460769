import { AntdLayout, Button, Icons, Space, Spin, Typography } from "@pankod/refine-antd";
import { useLogin } from "@pankod/refine-core";
import { useState } from "react";
import { LOGO_PATH } from "scripts/site";

export const Login: React.FC = () => {
    const { mutate: login, isLoading } = useLogin();
    const [loginDisabled, setLoginDisabled] = useState(false);

    return (
        <AntdLayout>
            <div style={{ height: "100vh", display: "flex" }}>
                <div style={{
                    margin: "auto"
                }}>
                    <Space direction="vertical" wrap align="center" >
                        <div style={{ marginBottom: "16px" }}>
                            <img src={LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle} style={{ maxWidth: "200px" }} />
                        </div>

                        <Typography.Title level={4} style={{ marginBottom: "20px" }}>
                            {`Welcome to ${process.env.REACT_APP_ApplicationTitle}`}
                        </Typography.Title>

                        <div style={{ width: 150 }}>
                            <Spin spinning={isLoading || loginDisabled}>
                                <Button
                                    block
                                    type="primary"
                                    disabled={loginDisabled}
                                    size="large"
                                    onClick={() => {
                                        setLoginDisabled(true);
                                        login({});
                                    }}
                                    // className="signin-button"
                                    >
                                    Sign in
                                    <Icons.LoginOutlined />
                                </Button>
                            </Spin>
                        </div>
                    </Space>
                </div>
            </div>
        </AntdLayout>
    );
};