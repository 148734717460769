import { Result } from "@pankod/refine-antd";
import { Typography } from "@pankod/refine-antd";
export const AppError = <Result
    status="403"
    title="403: Not Authorized"
    subTitle={
        <>
            <Typography.Text> Sorry, you are not authorized to access this page.</Typography.Text>
        </>
    }
/>;